import React, { useState } from "react";
import {
  Col,
  Button,
  Modal,
  ModalBody,
  Input,
  Label,
  FormFeedback,
} from "reactstrap";

import * as Yup from "yup";
import { Formik, Form } from "formik";

const NewsModal = ({
  customer = {},
  isOpen,
  setIsOpen,
  setActiveNewsIndex = () => {},
  loadNewsData,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values) => {
    console.log("values", values);
    const data = {
      ...values,
    };
    setIsLoading(true);
    // Delete for now
    delete data.auctionTime;
    delete data.regName;

    try {
      if (customer?.id) {
        // await editNews(data, customer?.id);
      } else {
        // await addNewNews(data);
      }
      await loadNewsData();
      setIsOpen(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = (formik) => {
    formik.resetForm();
    setIsOpen(false);
    setActiveNewsIndex(-1);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen();
      }}
      style={{
        width: "1200px",
        maxWidth: "90vw",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {/* <ModalHeader> */}
      <Formik
        enableReinitialize={true}
        initialValues={{
          id: customer?.id || "",
          name: customer?.name || "",
          regName: customer?.regName || "",
          email: customer?.email || "",
          contact: customer?.contact || "",
          phoneNumber: customer?.phoneNumber || "",
          faxNumber: customer?.faxNumber || "",
          website: customer?.website || "",
          physicalAddress: customer?.physicalAddress || "",
          mailingAddress: customer?.mailingAddress || "",
          notes: customer?.notes || "",
          storageRate: customer?.storageRate || "",
          lienFee: customer?.lienFee || "",
          advertFee: customer?.advertFee || "",
          heavyTowFee: customer?.heavyTowFee || "",
          watercraftFee: customer?.watercraftFee || "",
          auctionDay: customer?.auctionDay || "",
          auctionTime: customer?.auctionTime || "",
          lienCredit: customer?.lienCredit || "",
        }}
        validationSchema={Yup.object({
          id: Yup.string().required("Please Enter ID"),
          name: Yup.string().required("Please Enter Name"),
          email: Yup.string()
            .email("Please Enter Correct Email")
            .required("Please Enter Email"),
          contact: Yup.string(),
          phoneNumber: Yup.string(),
          faxNumber: Yup.string(),
          website: Yup.string(),
          physicalAddress: Yup.string(),
          mailingAddress: Yup.string(),
          notes: Yup.string(),
          storageRate: Yup.number("Please Enter Number"),
          lienFee: Yup.number(),
          advertFee: Yup.number(),
          heavyTowFee: Yup.number(),
          watercraftFee: Yup.number(),
          auctionDay: Yup.string(),
          lienCredit: Yup.number(),
        })}
        onSubmit={async (values) => {
          onSubmit(values);
        }}
      >
        {(validation) => (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <h5 className="modal-title">
                {customer?.ccustid ? "Edit" : "Add"} News
              </h5>
              <Button
                type="button"
                onClick={() => onClose(validation)}
                className="btn-close"
                aria-label="Close"
              ></Button>
            </div>
            {/* </ModalHeader> */}
            <ModalBody>
              <Form>
                <div className="row g-3">
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="id" className="form-label">
                        News ID<span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="id"
                        type="text"
                        className="form-control"
                        placeholder="Enter ID"
                        id="id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.id || ""}
                        invalid={
                          validation.touched.id && validation.errors.id
                            ? true
                            : false
                        }
                      />
                      {validation.touched.id && validation.errors.id ? (
                        <FormFeedback type="invalid">
                          {validation.errors.id}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="name" className="form-label">
                        Name<span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        id="name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="regName" className="form-label">
                        Registered Name
                      </Label>
                      <Input
                        name="regName"
                        type="text"
                        className="form-control"
                        placeholder="Enter Registered Name"
                        id="regName"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.regName || ""}
                        invalid={
                          validation.touched.regName &&
                          validation.errors.regName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.regName &&
                      validation.errors.regName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.regName}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="email" className="form-label">
                        Email <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="email"
                        type="text"
                        className="form-control"
                        placeholder="Enter Email"
                        id="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="contact" className="form-label">
                        Contact
                      </Label>
                      <Input
                        name="contact"
                        type="text"
                        className="form-control"
                        placeholder="Enter Contact"
                        id="contact"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.contact || ""}
                        invalid={
                          validation.touched.contact &&
                          validation.errors.contact
                            ? true
                            : false
                        }
                      />
                      {validation.touched.contact &&
                      validation.errors.contact ? (
                        <FormFeedback type="invalid">
                          {validation.errors.contact}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="phoneNumber" className="form-label">
                        Phone Number
                      </Label>
                      <Input
                        name="phoneNumber"
                        type="text"
                        className="form-control"
                        placeholder="Enter Phone Number"
                        id="phoneNumber"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.phoneNumber || ""}
                        invalid={
                          validation.touched.phoneNumber &&
                          validation.errors.phoneNumber
                            ? true
                            : false
                        }
                      />
                      {validation.touched.phoneNumber &&
                      validation.errors.phoneNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.phoneNumber}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="faxNumber" className="form-label">
                        Fax Number
                      </Label>
                      <Input
                        name="faxNumber"
                        type="text"
                        className="form-control"
                        placeholder="Enter Address"
                        id="faxNumber"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.faxNumber || ""}
                        invalid={
                          validation.touched.faxNumber &&
                          validation.errors.faxNumber
                            ? true
                            : false
                        }
                      />
                      {validation.touched.faxNumber &&
                      validation.errors.faxNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.faxNumber}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="website" className="form-label">
                        Website
                      </Label>
                      <Input
                        name="website"
                        type="text"
                        className="form-control"
                        placeholder="Enter Website"
                        id="website"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.website || ""}
                        invalid={
                          validation.touched.website &&
                          validation.errors.website
                            ? true
                            : false
                        }
                      />
                      {validation.touched.website &&
                      validation.errors.website ? (
                        <FormFeedback type="invalid">
                          {validation.errors.website}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="physicalAddress" className="form-label">
                        Physical Address
                      </Label>
                      <Input
                        name="physicalAddress"
                        type="text"
                        className="form-control"
                        placeholder="Enter Country"
                        id="physicalAddress"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.physicalAddress || ""}
                        invalid={
                          validation.touched.physicalAddress &&
                          validation.errors.physicalAddress
                            ? true
                            : false
                        }
                      />
                      {validation.touched.physicalAddress &&
                      validation.errors.physicalAddress ? (
                        <FormFeedback type="invalid">
                          {validation.errors.physicalAddress}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="mailingAddress" className="form-label">
                        Mailing Address
                      </Label>
                      <Input
                        name="mailingAddress"
                        type="text"
                        className="form-control"
                        placeholder="Enter Mailing Address"
                        id="mailingAddress"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.mailingAddress || ""}
                        invalid={
                          validation.touched.mailingAddress &&
                          validation.errors.mailingAddress
                            ? true
                            : false
                        }
                      />
                      {validation.touched.mailingAddress &&
                      validation.errors.mailingAddress ? (
                        <FormFeedback type="invalid">
                          {validation.errors.mailingAddress}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="notes" className="form-label">
                        Notes
                      </Label>
                      <Input
                        name="notes"
                        type="text"
                        className="form-control"
                        placeholder="Enter Notes"
                        id="notes"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.notes || ""}
                        invalid={
                          validation.touched.notes && validation.errors.notes
                            ? true
                            : false
                        }
                      />
                      {validation.touched.notes && validation.errors.notes ? (
                        <FormFeedback type="invalid">
                          {validation.errors.notes}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="storageRate" className="form-label">
                        Storage Rate
                      </Label>
                      <Input
                        name="storageRate"
                        type="number"
                        className="form-control"
                        placeholder="Enter Storage Rate"
                        id="storageRate"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.storageRate || ""}
                        invalid={
                          validation.touched.storageRate &&
                          validation.errors.storageRate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.storageRate &&
                      validation.errors.storageRate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.storageRate}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="lienFee" className="form-label">
                        Lien Fee
                      </Label>
                      <Input
                        name="lienFee"
                        type="number"
                        className="form-control"
                        placeholder="Enter Lien Fee"
                        id="lienFee"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.lienFee || ""}
                        invalid={
                          validation.touched.lienFee &&
                          validation.errors.lienFee
                            ? true
                            : false
                        }
                      />
                      {validation.touched.lienFee &&
                      validation.errors.lienFee ? (
                        <FormFeedback type="invalid">
                          {validation.errors.lienFee}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="advertFee" className="form-label">
                        Advert Fee
                      </Label>
                      <Input
                        name="advertFee"
                        type="number"
                        className="form-control"
                        placeholder="Enter Advert Fee"
                        id="advertFee"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.advertFee || ""}
                        invalid={
                          validation.touched.advertFee &&
                          validation.errors.advertFee
                            ? true
                            : false
                        }
                      />
                      {validation.touched.advertFee &&
                      validation.errors.advertFee ? (
                        <FormFeedback type="invalid">
                          {validation.errors.advertFee}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="heavyTowFee" className="form-label">
                        Heavy Tow Fee
                      </Label>
                      <Input
                        name="heavyTowFee"
                        type="number"
                        className="form-control"
                        placeholder="Enter Heavy Tow Fee"
                        id="heavyTowFee"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.heavyTowFee || ""}
                        invalid={
                          validation.touched.heavyTowFee &&
                          validation.errors.heavyTowFee
                            ? true
                            : false
                        }
                      />
                      {validation.touched.heavyTowFee &&
                      validation.errors.heavyTowFee ? (
                        <FormFeedback type="invalid">
                          {validation.errors.heavyTowFee}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="watercraftFee" className="form-label">
                        Watercraft Fee
                      </Label>
                      <Input
                        name="watercraftFee"
                        type="number"
                        className="form-control"
                        placeholder="Enter Watercraft Fee"
                        id="watercraftFee"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.watercraftFee || ""}
                        invalid={
                          validation.touched.watercraftFee &&
                          validation.errors.watercraftFee
                            ? true
                            : false
                        }
                      />
                      {validation.touched.watercraftFee &&
                      validation.errors.watercraftFee ? (
                        <FormFeedback type="invalid">
                          {validation.errors.watercraftFee}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="auctionDay" className="form-label">
                        Auction Day
                      </Label>
                      <Input
                        name="auctionDay"
                        type="text"
                        className="form-control"
                        placeholder="Enter Auction Day"
                        id="auctionDay"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.auctionDay || ""}
                        invalid={
                          validation.touched.auctionDay &&
                          validation.errors.auctionDay
                            ? true
                            : false
                        }
                      />
                      {validation.touched.auctionDay &&
                      validation.errors.auctionDay ? (
                        <FormFeedback type="invalid">
                          {validation.errors.auctionDay}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="auctionTime" className="form-label">
                        Auction Time
                      </Label>
                      <Input
                        name="auctionTime"
                        type="time"
                        className="form-control"
                        placeholder="Enter Auction Day"
                        id="auctionTime"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.auctionTime || ""}
                        invalid={
                          validation.touched.auctionTime &&
                          validation.errors.auctionTime
                            ? true
                            : false
                        }
                      />
                      {validation.touched.auctionTime &&
                      validation.errors.auctionTime ? (
                        <FormFeedback type="invalid">
                          {validation.errors.auctionTime}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="lienCredit" className="form-label">
                        Lien Credit
                      </Label>
                      <Input
                        name="lienCredit"
                        type="number"
                        className="form-control"
                        placeholder="Enter Lien Credit"
                        id="lienCredit"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.lienCredit || ""}
                        invalid={
                          validation.touched.lienCredit &&
                          validation.errors.lienCredit
                            ? true
                            : false
                        }
                      />
                      {validation.touched.lienCredit &&
                      validation.errors.lienCredit ? (
                        <FormFeedback type="invalid">
                          {validation.errors.lienCredit}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <div className="col-lg-12">
                    <div className="hstack gap-2 justify-content-end">
                      <Button color="light" onClick={() => onClose(validation)}>
                        Close
                      </Button>
                      <Button color="primary" disabled={isLoading}>
                        {isLoading
                          ? customer?.id
                            ? "Editing..."
                            : "Adding ..."
                          : customer?.id
                          ? "Edit News"
                          : "Add News"}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </ModalBody>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default NewsModal;
