import AppService, { APIClient } from "./api_helper";

import * as url from "./url_helper";
// import { api as urls } from "../config";
import axios from "axios";

const api = new APIClient();

const API_URL = url.API_URL;

export const loginUser = async (data) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.LOGIN}`,
      method: "POST",
      params: data,
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getUserPermissions = async () => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.PERMISSIONS}`,
      method: "GET",
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllUsers = async () => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.USERS}`,
      method: "GET",
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getUserById = async (id) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.USERS}?userID=${id}`,
      method: "GET",
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const addUserAPI = async (data) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.USERS}`,
      method: "POST",
      params: data,
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateUserAPI = async (data, user) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.USERS}/${user}`,
      method: "PUT",
      params: data,
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateUserPasswordAPI = async (data) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.USERS}/updatePassword`,
      method: "PUT",
      params: data,
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteUserAPI = async (user) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.USERS}/${user}`,
      method: "DELETE",
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllCustomers = async () => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.CUSTOMERS}`,
      method: "GET",
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const addNewCustomer = async (data) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.CUSTOMERS}`,
      method: "POST",
      params: data,
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const editCustomer = async (data, id) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.CUSTOMERS}/${id}`,
      method: "PUT",
      params: data,
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteCustomer = async (id) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.CUSTOMERS}/${id}`,
      method: "DELETE",
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

// Dashboard

export const getDashboardData = async () => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.DASHBOARD}`,
      method: "GET",
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getDashboardRequestsData = async () => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.DASHBOARD}/requests`,
      method: "GET",
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSettings = async () => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.SETTINGS}`,
      method: "GET",
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getRequests = async () => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.REQUESTS}`,
      method: "GET",
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const filterRequests = async (data = {}) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.REQUESTS}/search?vin=${data.vin || ""}&plate=${
        data.plate || ""
      }`,
      method: "GET",
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const printRequestFormApi = async () => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.REQUEST_FORM}`,
      method: "GET",
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const createRequest = async (data) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.REQUESTS}`,
      method: "POST",
      params: data,
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getRequestTowReasons = async () => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.REQUESTS}/towReasonOptions`,
      method: "GET",
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateRequestById = async (id, data) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.REQUESTS}/${id}`,
      method: "PUT",
      params: data,
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateRequest = async (data) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.REQUESTS}`,
      method: "PUT",
      params: data,
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const unlockRequestById = async (id, data) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.REQUESTS}/${id}/unlock`,
      method: "PUT",
      params: data,
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const uploadRequestDocument = async (data) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.DOCUMENTS}`,
      method: "PUT",
      form: data,
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateDocumentById = async (id, doc, data) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.REQUESTS}/${id}/documents/${doc}`,
      method: "PUT",
      form: data,
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteDocumentById = async (id, doc) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.REQUESTS}/${id}/documents/${doc}`,
      method: "DELETE",
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteRequestById = async (id) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.REQUESTS}`,
      params: { id },
      method: "DELETE",
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const lockRequestById = async (id) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.REQUESTS}/lock`,
      params: { id },
      method: "PUT",
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const unLockRequestById = async (id) => {
  try {
    const response = await AppService({
      url: `${API_URL}${url.REQUESTS}/unlock`,
      params: { id },
      method: "PUT",
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
