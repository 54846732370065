import React, { useEffect, useState } from "react";
import {
  Col,
  Button,
  Modal,
  ModalBody,
  Input,
  Label,
  FormFeedback,
  FormGroup,
} from "reactstrap";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
  addNewCustomer,
  createRequest,
  deleteDocumentById,
  editCustomer,
  unlockRequestById,
  updateDocumentById,
  updateRequestById,
  uploadRequestDocument,
} from "../../helpers/backend_helper";
import ReactSelect from "../../Components/Common/ReactSelect";

// {"documents":["Tow_Receipt.pdf"]}

const RequestModal = ({
  request = {},
  isOpen,
  setIsOpen,
  setActiveRequestIndex,
  loadRequestData,
  towReasons,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [deletingFilesLoader, setDeletingFilesLoader] = useState([]);

  const updateDocument = async () => {
    try {
      const data = new FormData();
      data.append("file", file);
      await updateDocumentById(request?.id, file?.name, data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const uploadDocument = async (id) => {
    try {
      const data = new FormData();
      data.append("requestID", id);
      data.append("file", file);
      await uploadRequestDocument(data);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const deleteDocument = async (d) => {
    try {
      if (!deletingFilesLoader?.includes(d)) {
        setDeletingFilesLoader((prev) => [...prev, d]);
      }
      await deleteDocumentById(request?.id, d);
      setIsLoading(false);
      await loadRequestData();
      setDeletingFilesLoader((prev) => prev.filter((n) => n !== d));
    } catch (err) {}
  };

  const onSubmit = async (values, formikRef) => {
    setIsLoading(true);
    const data = { ...values };
    if (values.street || values.city || values.addressState || values.zipCode) {
      data.ownerAddress = `${values.street}, ${values.city}, ${values.addressState} ${values.zipCode}`;
    }
    delete data.street;
    delete data.city;
    delete data.addressState;
    delete data.zipCode;
    try {
      if (request?.id) {
        await updateRequestById(request?.id, data);
        if (file) {
          await updateDocument();
        }
      } else {
        // if (!file) {
        //   formikRef.setFieldError("documents", "Please Upload PDF file.");
        //   return;
        // }
        const res = await createRequest(data);
        await uploadDocument(res?.id);
        if (res?.id) {
          setIsOpen(false);
        }
      }
      await loadRequestData();
      setIsOpen(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = (formik) => {
    formik.resetForm();
    setIsOpen(false);
    setActiveRequestIndex(-1);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen();
      }}
      style={{
        width: "1200px",
        maxWidth: "90vw",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {/* <ModalHeader> */}
      <Formik
        enableReinitialize={true}
        initialValues={{
          street: request?.ownerAddress?.split(",")?.[0] || "",
          city: request?.ownerAddress?.split(",")?.[1] || "",
          addressState:
            request?.ownerAddress?.split(",")?.[2]?.split(" ")?.[1] || "",
          zipCode:
            request?.ownerAddress?.split(",")?.[2]?.split(" ")?.[2] || "",
          auctionDate: request?.auctionDate || "",
          customerID: request?.customerID || "",
          storageAmount: request?.storageAmount || "",
          towReason: request?.towReason || "",
          towDate: request?.towDate || "",
          // lienLength: request?.lienLength || "",
          storageRate: request?.storageRate || "",
          state: request?.state || "",
          //  noticeDate: request?.noticeDate || "",
          notes: request?.notes || "",
          // lockedBy: request?.lockedBy || "",
          noticeFee: request?.noticeFee || "",
          //  id: request?.id || "",
          //  requestID: request?.requestID || "",
          model: request?.model || "",
          towAmount: request?.towAmount || "",
          plate: request?.plate || "",
          //  auctionFee: request?.auctionFee || "",
          //  cancelDate: request?.cancelDate || "",
          totaled: request?.totaled || "",
          //  lienFee: request?.lienFee || "",
          make: request?.make || "",
          ownerName: request?.ownerName || "",
          watercraft: request?.watercraft || "",
          style: request?.style || "",
          vin: request?.vin || "",
          year: request?.year || "",
          heavy: request?.heavy || "",
          //  importDate: request?.importDate || "",
          ownerAddress: request?.ownerAddress || "",
          referenceID: request?.referenceID || "",
          dailyRate: request?.dailyRate || "",
          // complete: request?.complete || false,
          status: request?.status || "",
        }}
        validationSchema={Yup.object({
          customerID: Yup.string().required("Please Enter Customer Id"),
          vin: Yup.string().required("Please Enter VIN"),
          plate: Yup.string(),
          //  id: Yup.string().required('Please Enter ID'),
          storageAmount: Yup.number().typeError("Please Enter a number"),
          dailyRate: Yup.number().typeError("Please Enter a number"),
          storageRate: Yup.number().typeError("Please Enter a number"),
          // lienLength: Yup.number().typeError("Please Enter a number"),
          noticeFee: Yup.number().typeError("Please Enter a number"),
          towAmount: Yup.number().typeError("Please Enter a number"),
          //  auctionFee: Yup.number().typeError('Please Enter a number'),
          //  lienFee: Yup.number().typeError('Please Enter a number'),
          totaled: Yup.bool().typeError("Please Enter a boolean"),
          watercraft: Yup.bool().typeError("Please Enter a boolean"),
          heavy: Yup.bool().typeError("Please Enter a boolean"),
          // complete: Yup.bool().typeError("Please Enter a boolean"),
          year: Yup.string()
            .matches(/^[0-9]{4}$/, "Invalid year format")
            .test("valid-year", "Invalid year", (value) => {
              if (value) {
                const year = parseInt(value, 10);
                return year >= 1900 && year <= 3000;
              }
              return true;
            }),
        })}
        onSubmit={onSubmit}
      >
        {(validation) => (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <h5 className="modal-title">
                {request?.id ? "Edit" : "Add"} Request
              </h5>
              <Button
                type="button"
                onClick={() => onClose(validation)}
                className="btn-close"
                aria-label="Close"
              ></Button>
            </div>
            {/* </ModalHeader> */}
            <ModalBody>
              <Form>
                <div className="row g-3">
                  {/* <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="id" className="form-label">
                      ID<span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="id"
                        type="number"
                        className="form-control"
                        placeholder="Enter ID"
                        id="id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.id || ""}
                        invalid={
                          validation.touched.id &&
                          validation.errors.id
                            ? true
                            : false
                        }
                        disabled={request?.id}
                      />
                      {validation.touched.id &&
                      validation.errors.id ? (
                        <FormFeedback type="invalid">
                          {validation.errors.id}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col> */}
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="documents" className="form-label">
                        Document
                      </Label>
                      <Input
                        name="documents"
                        type="file"
                        accept="application/pdf"
                        className="form-control"
                        placeholder="Select Document"
                        id="documents"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                          validation.setFieldError("documents", "");
                        }}
                        invalid={validation.errors.documents}
                      />
                      {request?.documents?.length > 0 && (
                        <p className="mb-1 mt-2">Uploaded File(s): </p>
                      )}
                      {request?.documents?.map((d) => (
                        <div className="d-flex justify-content-between">
                          <span>{d}</span>
                          <span
                            onClick={() => deleteDocument(d)}
                            className=" link-danger fs-15 "
                          >
                            {deletingFilesLoader.includes(d) ? (
                              <i className="ri-loader-line circular-spin"></i>
                            ) : (
                              <i className="ri-delete-bin-line cursor-pointer"></i>
                            )}
                          </span>
                        </div>
                      ))}
                      {validation.errors.documents ? (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {validation.errors.documents}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="customerID" className="form-label">
                        Customer ID<span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="customerID"
                        type="text"
                        className="form-control"
                        placeholder="Enter Customer ID"
                        id="customerID"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.customerID || ""}
                        invalid={
                          validation.touched.customerID &&
                          validation.errors.customerID
                            ? true
                            : false
                        }
                      />
                      {validation.touched.customerID &&
                      validation.errors.customerID ? (
                        <FormFeedback type="invalid">
                          {validation.errors.customerID}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={12}>
                    <h4>Personal Information</h4>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="ownerName" className="form-label">
                        Owner Name
                      </Label>
                      <Input
                        name="ownerName"
                        type="text"
                        className="form-control"
                        placeholder="Enter Owner Name"
                        id="ownerName"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.ownerName || ""}
                        invalid={
                          validation.touched.ownerName &&
                          validation.errors.ownerName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.ownerName &&
                      validation.errors.ownerName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.ownerName}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={12}>
                    <h4>Owner Address</h4>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="street" className="form-label">
                        Street
                      </Label>
                      <Input
                        name="street"
                        type="text"
                        className="form-control"
                        placeholder="Enter Street"
                        id="street"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.street || ""}
                        invalid={
                          validation.touched.street && validation.errors.street
                            ? true
                            : false
                        }
                      />
                      {validation.touched.street && validation.errors.street ? (
                        <FormFeedback type="invalid">
                          {validation.errors.street}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="city" className="form-label">
                        City
                      </Label>
                      <Input
                        name="city"
                        type="text"
                        className="form-control"
                        placeholder="Enter City"
                        id="city"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.city || ""}
                        invalid={
                          validation.touched.city && validation.errors.city
                            ? true
                            : false
                        }
                      />
                      {validation.touched.city && validation.errors.city ? (
                        <FormFeedback type="invalid">
                          {validation.errors.city}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="addressState" className="form-label">
                        State
                      </Label>
                      <Input
                        name="addressState"
                        type="text"
                        className="form-control"
                        placeholder="Enter State"
                        id="addressState"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addressState || ""}
                        invalid={
                          validation.touched.addressState &&
                          validation.errors.addressState
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addressState &&
                      validation.errors.addressState ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addressState}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="zipCode" className="form-label">
                        Zip Code
                      </Label>
                      <Input
                        name="zipCode"
                        type="text"
                        className="form-control"
                        placeholder="Enter Zip Code"
                        id="zipCode"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.zipCode || ""}
                        invalid={
                          validation.touched.zipCode &&
                          validation.errors.zipCode
                            ? true
                            : false
                        }
                      />
                      {validation.touched.zipCode &&
                      validation.errors.zipCode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.zipCode}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={12}>
                    <h4>Vehicle Information</h4>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="year" className="form-label">
                        Year
                      </Label>
                      <Input
                        name="year"
                        type="number"
                        className="form-control"
                        placeholder="Enter Year"
                        id="year"
                        min="yyyy-01-01"
                        max="yyyy-12-31"
                        step="1"
                        pattern="[0-9]{4}"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.year || ""}
                        invalid={
                          validation.touched.year && validation.errors.year
                            ? true
                            : false
                        }
                      />
                      {validation.touched.year && validation.errors.year ? (
                        <FormFeedback type="invalid">
                          {validation.errors.year}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="make" className="form-label">
                        Make
                      </Label>
                      <Input
                        name="make"
                        type="text"
                        className="form-control"
                        placeholder="Enter Make"
                        id="make"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.make || ""}
                        invalid={
                          validation.touched.make && validation.errors.make
                            ? true
                            : false
                        }
                      />
                      {validation.touched.make && validation.errors.make ? (
                        <FormFeedback type="invalid">
                          {validation.errors.make}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="model" className="form-label">
                        Model
                      </Label>
                      <Input
                        name="model"
                        type="text"
                        className="form-control"
                        placeholder="Enter Model"
                        id="model"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.model || ""}
                        invalid={
                          validation.touched.model && validation.errors.model
                            ? true
                            : false
                        }
                      />
                      {validation.touched.model && validation.errors.model ? (
                        <FormFeedback type="invalid">
                          {validation.errors.model}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="vin" className="form-label">
                        VIN<span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="vin"
                        type="text"
                        className="form-control"
                        placeholder="Enter VIN"
                        id="vin"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.vin || ""}
                        invalid={
                          validation.touched.vin && validation.errors.vin
                            ? true
                            : false
                        }
                      />
                      {validation.touched.vin && validation.errors.vin ? (
                        <FormFeedback type="invalid">
                          {validation.errors.vin}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="state" className="form-label">
                        State
                      </Label>
                      <Input
                        name="state"
                        type="text"
                        className="form-control"
                        placeholder="Enter State"
                        id="state"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.state || ""}
                        invalid={
                          validation.touched.state && validation.errors.state
                            ? true
                            : false
                        }
                      />
                      {validation.touched.state && validation.errors.state ? (
                        <FormFeedback type="invalid">
                          {validation.errors.state}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="plate" className="form-label">
                        Plate
                      </Label>
                      <Input
                        name="plate"
                        type="text"
                        className="form-control"
                        placeholder="Enter Plate"
                        id="plate"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.plate || ""}
                        invalid={
                          validation.touched.plate && validation.errors.plate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.plate && validation.errors.plate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.plate}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="style" className="form-label">
                        Style
                      </Label>
                      <Input
                        name="style"
                        type="text"
                        className="form-control"
                        placeholder="Enter Style"
                        id="style"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.style || ""}
                        invalid={
                          validation.touched.style && validation.errors.style
                            ? true
                            : false
                        }
                      />
                      {validation.touched.style && validation.errors.style ? (
                        <FormFeedback type="invalid">
                          {validation.errors.style}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col sm={12}>
                    <h4>Service Information</h4>
                  </Col>

                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="auctionDate" className="form-label">
                        Auction Date
                      </Label>
                      <Input
                        name="auctionDate"
                        type="date"
                        className="form-control"
                        placeholder="Enter Auction Date"
                        id="auctionDate"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.auctionDate || ""}
                        invalid={
                          validation.touched.auctionDate &&
                          validation.errors.auctionDate
                            ? true
                            : false
                        }
                        disabled={request?.status !== "completed"}
                      />
                      {validation.touched.auctionDate &&
                      validation.errors.auctionDate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.auctionDate}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="storageAmount" className="form-label">
                        Service Amount
                      </Label>
                      <Input
                        name="storageAmount"
                        type="number"
                        className="form-control"
                        placeholder="Enter Service Amount"
                        id="storageAmount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.storageAmount || ""}
                        invalid={
                          validation.touched.storageAmount &&
                          validation.errors.storageAmount
                            ? true
                            : false
                        }
                        disabled={request?.status !== "completed"}
                      />
                      {validation.touched.storageAmount &&
                      validation.errors.storageAmount ? (
                        <FormFeedback type="invalid">
                          {validation.errors.storageAmount}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="dailyRate" className="form-label">
                        Daily Rate
                      </Label>
                      <Input
                        name="dailyRate"
                        type="number"
                        className="form-control"
                        placeholder="Enter Daily Rate"
                        id="dailyRate"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.dailyRate || ""}
                        invalid={
                          validation.touched.dailyRate &&
                          validation.errors.dailyRate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.dailyRate &&
                      validation.errors.dailyRate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.dailyRate}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <ReactSelect
                        labelName="Reason"
                        options={
                          towReasons?.length > 0
                            ? towReasons?.map((r) => ({
                                label: r,
                                value: r,
                              }))
                            : []
                        }
                        optionLabel="label"
                        optionValue="value"
                        formikObj={validation}
                        id="towReason"
                        placeholder="Select Reason"
                      />

                      {validation.touched.towReason &&
                      validation.errors.towReason ? (
                        <FormFeedback type="invalid">
                          {validation.errors.towReason}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="towDate" className="form-label">
                        Service Date
                      </Label>
                      <Input
                        name="towDate"
                        type="date"
                        className="form-control"
                        placeholder="Enter Auction Date"
                        id="towDate"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.towDate || ""}
                        invalid={
                          validation.touched.towDate &&
                          validation.errors.towDate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.towDate &&
                      validation.errors.towDate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.towDate}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  {/*   <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="lienLength" className="form-label">
                        Lien Length
                      </Label>
                      <Input
                        name="lienLength"
                        type="text"
                        className="form-control"
                        placeholder="Enter Lien Length"
                        id="lienLength"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.lienLength || ""}
                        invalid={
                          validation.touched.lienLength &&
                          validation.errors.lienLength
                            ? true
                            : false
                        }
                      />
                      {validation.touched.lienLength &&
                      validation.errors.lienLength ? (
                        <FormFeedback type="invalid">
                          {validation.errors.lienLength}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                   <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="storageRate" className="form-label">
                      Storage Rate
                      </Label>
                      <Input
                        name="storageRate"
                        type="text"
                        className="form-control"
                        placeholder="Enter Storage Amount"
                        id="storageRate"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.storageRate || ""}
                        invalid={
                          validation.touched.storageRate &&
                          validation.errors.storageRate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.storageRate &&
                      validation.errors.storageRate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.storageRate}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col> */}

                  {/* <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="noticeDate" className="form-label">
                       Notice Date
                      </Label>
                      <Input
                        name="noticeDate"
                        type="date"
                        className="form-control"
                        placeholder="Enter Auction Date"
                        id="noticeDate"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.noticeDate || ""}
                        invalid={
                          validation.touched.noticeDate &&
                          validation.errors.noticeDate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.noticeDate &&
                      validation.errors.noticeDate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.noticeDate}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col> */}

                  {/* <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="lockedBy" className="form-label">
                        Locked By
                      </Label>
                      <Input
                        name="lockedBy"
                        type="text"
                        className="form-control"
                        placeholder="Enter Locked By"
                        id="lockedBy"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.lockedBy || ""}
                        invalid={
                          validation.touched.lockedBy &&
                          validation.errors.lockedBy
                            ? true
                            : false
                        }
                      />
                      {validation.touched.lockedBy &&
                      validation.errors.lockedBy ? (
                        <FormFeedback type="invalid">
                          {validation.errors.lockedBy}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col> */}
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="noticeFee" className="form-label">
                        Notice Fee
                      </Label>
                      <Input
                        name="noticeFee"
                        type="number"
                        className="form-control"
                        placeholder="Enter Notice Fee"
                        id="noticeFee"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.noticeFee || ""}
                        invalid={
                          validation.touched.noticeFee &&
                          validation.errors.noticeFee
                            ? true
                            : false
                        }
                        disabled={request?.status !== "completed"}
                      />
                      {validation.touched.noticeFee &&
                      validation.errors.noticeFee ? (
                        <FormFeedback type="invalid">
                          {validation.errors.noticeFee}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  {/* <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="requestID" className="form-label">
                      Request ID
                      </Label>
                      <Input
                        name="requestID"
                        type="text"
                        className="form-control"
                        placeholder="Enter Request ID"
                        id="requestID"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.requestID || ""}
                        invalid={
                          validation.touched.requestID &&
                          validation.errors.requestID
                            ? true
                            : false
                        }
                      />
                      {validation.touched.requestID &&
                      validation.errors.requestID ? (
                        <FormFeedback type="invalid">
                          {validation.errors.requestID}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col> */}

                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="towAmount" className="form-label">
                        Tow Amount
                      </Label>
                      <Input
                        name="towAmount"
                        type="number"
                        className="form-control"
                        placeholder="Enter Tow Amount"
                        id="towAmount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.towAmount || ""}
                        invalid={
                          validation.touched.towAmount &&
                          validation.errors.towAmount
                            ? true
                            : false
                        }
                        disabled={request?.status !== "completed"}
                      />
                      {validation.touched.towAmount &&
                      validation.errors.towAmount ? (
                        <FormFeedback type="invalid">
                          {validation.errors.towAmount}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  {/* <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="auctionFee" className="form-label">
                      Auction Fee
                      </Label>
                      <Input
                        name="auctionFee"
                        type="text"
                        className="form-control"
                        placeholder="Enter Auction Fee"
                        id="auctionFee"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.auctionFee || ""}
                        invalid={
                          validation.touched.auctionFee &&
                          validation.errors.auctionFee
                            ? true
                            : false
                        }
                      />
                      {validation.touched.auctionFee &&
                      validation.errors.auctionFee ? (
                        <FormFeedback type="invalid">
                          {validation.errors.auctionFee}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col> */}
                  {/* <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="cancelDate" className="form-label">
                      Cancel Date
                      </Label>
                      <Input
                        name="cancelDate"
                        type="date"
                        className="form-control"
                        placeholder="Enter Cancel Date"
                        id="cancelDate"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.cancelDate || ""}
                        invalid={
                          validation.touched.cancelDate &&
                          validation.errors.cancelDate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.cancelDate &&
                      validation.errors.cancelDate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.cancelDate}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col> */}

                  {/* <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="lienFee" className="form-label">
                      Lien Fee
                      </Label>
                      <Input
                        name="lienFee"
                        type="text"
                        className="form-control"
                        placeholder="Enter Lien Fee"
                        id="lienFee"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.lienFee || ""}
                        invalid={
                          validation.touched.lienFee &&
                          validation.errors.lienFee
                            ? true
                            : false
                        }
                      />
                      {validation.touched.lienFee &&
                      validation.errors.lienFee ? (
                        <FormFeedback type="invalid">
                          {validation.errors.lienFee}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col> */}

                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="referenceID" className="form-label">
                        Reference ID
                      </Label>
                      <Input
                        name="referenceID"
                        type="text"
                        className="form-control"
                        placeholder="Enter Reference ID"
                        id="referenceID"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.referenceID || ""}
                        invalid={
                          validation.touched.referenceID &&
                          validation.errors.referenceID
                            ? true
                            : false
                        }
                      />
                      {validation.touched.referenceID &&
                      validation.errors.referenceID ? (
                        <FormFeedback type="invalid">
                          {validation.errors.referenceID}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <ReactSelect
                        labelName="Status"
                        options={[
                          { label: "15", value: "15" },
                          { label: "30", value: "30" },
                          { label: "60", value: "60" },
                          { label: "AD", value: "AD" },
                        ]}
                        optionLabel="label"
                        optionValue="value"
                        formikObj={validation}
                        id="status"
                        placeholder="Select Status"
                      />

                      {validation.touched.status && validation.errors.status ? (
                        <FormFeedback type="invalid">
                          {validation.errors.status}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <FormGroup
                      check
                      inline
                      className="d-flex align-items-center gap-2 h-100"
                    >
                      <Input
                        name="heavy"
                        type="checkbox"
                        className=""
                        placeholder="Enter Heavy"
                        id="heavy"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.heavy || ""}
                        invalid={
                          validation.touched.heavy && validation.errors.heavy
                            ? true
                            : false
                        }
                      />
                      <Label className="mb-0" htmlFor="heavy">
                        Heavy
                      </Label>
                    </FormGroup>
                    {validation.touched.heavy && validation.errors.heavy ? (
                      <FormFeedback type="invalid">
                        {validation.errors.heavy}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <FormGroup
                      check
                      inline
                      className="d-flex align-items-center gap-2 h-100"
                    >
                      <Input
                        name="totaled"
                        type="checkbox"
                        className=""
                        placeholder="Enter Heavy"
                        id="totaled"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.totaled || ""}
                        invalid={
                          validation.touched.totaled &&
                          validation.errors.totaled
                            ? true
                            : false
                        }
                      />
                      <Label className="mb-0" htmlFor="totaled">
                        Totaled
                      </Label>
                    </FormGroup>

                    {validation.touched.totaled && validation.errors.totaled ? (
                      <FormFeedback type="invalid">
                        {validation.errors.totaled}
                      </FormFeedback>
                    ) : null}
                  </Col>

                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <FormGroup
                      check
                      inline
                      className="d-flex align-items-center gap-2 h-100"
                    >
                      <Input
                        name="watercraft"
                        type="checkbox"
                        className=""
                        placeholder="Enter Heavy"
                        id="watercraft"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.watercraft || ""}
                        invalid={
                          validation.touched.watercraft &&
                          validation.errors.watercraft
                            ? true
                            : false
                        }
                      />
                      <Label className="mb-0" htmlFor="watercraft">
                        Watercraft
                      </Label>
                    </FormGroup>
                    {validation.touched.watercraft &&
                    validation.errors.watercraft ? (
                      <FormFeedback type="invalid">
                        {validation.errors.watercraft}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  {/* <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="importDate" className="form-label">
                      Import Date
                      </Label>
                      <Input
                        name="importDate"
                        type="date"
                        className="form-control"
                        placeholder="Enter Import Date"
                        id="importDate"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.importDate || ""}
                        invalid={
                          validation.touched.importDate &&
                          validation.errors.importDate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.importDate &&
                      validation.errors.importDate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.importDate}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col> */}

                  {/* <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <ReactSelect
                        labelName="Complete"
                        options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                        ]}
                        optionLabel="label"
                        optionValue="value"
                        formikObj={validation}
                        id="complete"
                        placeholder="Select Complete"
                      />

                      {validation.touched.complete &&
                      validation.errors.complete ? (
                        <FormFeedback type="invalid">
                          {validation.errors.complete}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col> */}
                  <Col sm={12}>
                    <div>
                      <Label htmlFor="notes" className="form-label">
                        Notes
                      </Label>
                      <Input
                        name="notes"
                        type="text"
                        className="form-control"
                        placeholder="Enter Notes"
                        id="notes"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.notes || ""}
                        invalid={
                          validation.touched.notes && validation.errors.notes
                            ? true
                            : false
                        }
                      />
                      {validation.touched.notes && validation.errors.notes ? (
                        <FormFeedback type="invalid">
                          {validation.errors.notes}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <div className="col-lg-12">
                    <div className="hstack gap-2 justify-content-end">
                      <Button color="light" onClick={() => onClose(validation)}>
                        Close
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isLoading}
                      >
                        {isLoading
                          ? request?.id
                            ? "Editing..."
                            : "Adding ..."
                          : request?.id
                          ? "Edit Request"
                          : "Add Request"}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </ModalBody>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default RequestModal;
