import React, { useEffect, useState } from "react";

const Navdata = () => {
  const [navList, setNavList] = useState([
    {
      label: "pages",
      isHeader: true,
    },
  ]);

  useEffect(() => {
    const permissions = JSON.parse(sessionStorage.getItem("permissions"));
    const tempNavList = [...navList];
    if (permissions?.requests?.includes("read")) {
      tempNavList.push({
        id: "request",
        label: "Request",
        icon: " ri-git-pull-request-line",
        link: "/",
      });
    }
    if (permissions?.users?.includes("read")) {
      tempNavList.push({
        id: "dashboard",
        label: "Users",
        icon: "ri-user-line",
        link: "/users",
        stateVariables: false,
      });
    }
    if (permissions?.customers?.includes("read")) {
      tempNavList.push({
        id: "authentication",
        label: "Customers",
        icon: "ri-account-circle-line",
        link: "/customers",
      });
    }

    tempNavList.push({
      id: "authentication",
      label: "DMV",
      icon: "ri-car-line",
      link: "/dmv",
    });

    tempNavList.push({
      id: "authentication",
      label: "News",
      icon: "ri-article-line",
      link: "/news",
    });

    setNavList(tempNavList);
  }, [sessionStorage.getItem("permissions")]);

  return <React.Fragment>{navList}</React.Fragment>;
};
export default Navdata;
