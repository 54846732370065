import React, { useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";

const DeleteModal = ({ handleDelete, isOpen, setIsOpen }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onDelete = async () => {
    setIsLoading(true);
    try {
      await handleDelete();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen();
      }}
      style={{
        width: "500px",
        maxWidth: "90vw",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <h5 className="modal-title">Delete News</h5>
        <Button
          type="button"
          onClick={() => setIsOpen(false)}
          className="btn-close"
          aria-label="Close"
        ></Button>
      </div>

      {/* <ModalHeader> */}

      {/* </ModalHeader> */}
      <ModalBody>
        <p>
          Are you sure you want to delete this News? This action will make this
          item unrecoverable
        </p>
        <div className="">
          <div className="hstack gap-2 justify-content-end">
            <Button color="light" onClick={() => setIsOpen(false)}>
              Close
            </Button>
            <Button
              type="submit"
              color="danger"
              disabled={isLoading}
              onClick={onDelete}
            >
              {isLoading && (
                <svg
                  className={`circular-spin w-4 h-4  me-2  fill-current shrink-0`}
                  height="16"
                  width="16"
                  viewBox="0 0 16 16"
                  fill="#fff"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              )}
              Delete
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteModal;
